import React from "react";
//import { useEffect, useState } from "react";

function Usuario() {
  return (
    <div>
    <h1>Usuario Prueba</h1>
    </div>
  );
}

export default Usuario;
