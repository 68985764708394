import React from "react";
import { useEffect, useState, useRef } from "react";
import { estados } from '../../constantes/estados';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { localeEsp } from '../../constantes/localeEsp';
import { Utiles } from '../../utils/utiles';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import axios from 'axios';

function Trabajos() {
  const apiURL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [datosTabla, setDatosTabla] = useState(null);
  const [totalTabla, setTotalTabla] = useState('');
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [selectedSucursal, setSelectedSucursal] = useState({ name: 'Activo', code: '1' });
  const [selectedTrabajador, setSelectedTrabajador] = useState(null);

  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);

  const [listadoTrabajadores, setListadoTrabajadores] = useState(null);


  addLocale('es', localeEsp);

    const [datosInsert, setDatosInsert] = useState({
        id: 10,
        sucursal: '',
        aforo_maximo: null,
        estado: 1
    });

  
  useEffect(() => {
    var fechaDesde = new Date();
    fechaDesde.setHours(0, 0, 0, 0);
    fechaDesde.setDate( fechaDesde.getDate() - 41 );
    let fechaDesdeCS = Utiles.jsdtcs(fechaDesde);


    var fechaHoy = new Date();
    fechaHoy.setHours(0, 0, 0, 0);
    setFechaHasta(fechaHoy);
    setFechaDesde(fechaHoy);
    let fechaHoyCS = Utiles.jsdtcs(fechaHoy);

    axios.get(`${apiURL}SalonTrabajo/GetTrabajos/${fechaDesdeCS}/${fechaHoyCS}/0`,{
        headers: {
          'Authorization': `bearer ${localStorage.getItem('token')}`
        }})
       .then(response => {
        setDatosTabla(response.data);
        CalcularTotalTabla(response.data);
      })      
      .catch(err => {    
        if (err.response !== undefined) 
          toast.current.show({severity:'error', summary: '', detail: err.response.data, life: 10000});
        else
          toast.current.show({severity:'error', summary: '', detail: err.message, life: 10000});
    });


      fetch(
        `${apiURL}trabajador/GetListaTrabajadorasSalon?agregarTodas=1`,
        {
          method: "GET",
          //headers: new Headers({Accept: "application/vnd.github.cloak-preview"})
        }
      )
        .then(res => res.json())
        .then(response => {
           setListadoTrabajadores(response);
           //setCommitHistory(response.items);
           setSelectedTrabajador(0);
           setIsLoading(false);
        })
        .catch(error => console.log(error));
  },[]);


const onSucursalChange = (e) => {
  setDatosInsert({
    ...datosInsert,
    [e.target.name] : parseInt(e.value.code, 10)
  });
  setSelectedSucursal(e.value); 
}

const onTrabajadorChange = (e) => {
  console.log(e.value);
  setSelectedTrabajador(e.value); 
}

function btnSearchClick()
{
  axios.get(`${apiURL}SalonTrabajo/GetTrabajos/${Utiles.jsdtcs(fechaDesde)}/${Utiles.jsdtcs(fechaHasta)}/${selectedTrabajador}`,{
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }})
     .then(response => {
      setDatosTabla(response.data);
      CalcularTotalTabla(response.data);
    })      
    .catch(err => {    
      if (err.response !== undefined) 
        toast.current.show({severity:'error', summary: '', detail: err.response.data, life: 10000});
      else
        toast.current.show({severity:'error', summary: '', detail: err.message, life: 10000});
  });


  // fetch(
  //   `${apiURL}SalonTrabajo/GetTrabajos/${Utiles.jsdtcs(fechaDesde)}/${Utiles.jsdtcs(fechaHasta)}/${selectedTrabajador}`,
  //   {
  //     method: "GET",
  //     //headers: new Headers({Accept: "application/vnd.github.cloak-preview"})
  //   }
  // )
  //   .then(res => res.json())
  //   .then(response => {
  //      setDatosTabla(response);
  //      CalcularTotalTabla(response);
  //   })
  //   .catch(error => console.log(error));

}

function exportPdf() {
  datosTabla.map(col => console.log(col));

  let exportColumns = datosTabla.map(col => ({ title: "a", dataKey: "b" }));

  exportColumns = [
    { title: 'Fecha', dataKey: 'fecha' },
    { title: 'Manicurista', dataKey: 'manicurista' },
    { title: 'Servicio', dataKey: 'servicio' },
    { title: 'Precio', dataKey: 'precio' },
    { title: 'Boleta', dataKey: 'boleta' },
    { title: 'Desct.', dataKey: 'descuento' },
    { title: 'Total', dataKey: 'total' }
] ;


  import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(() => {
          const doc = new jsPDF.default(0, 0);
          //doc.autoTable(exportColumns, datosTabla);
          doc.autoTable(exportColumns,datosTabla);
          doc.save('products.pdf');
      })
  })
}

function exportExcel() {
  import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(datosTabla);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer, 'products');
  });
}

function saveAsExcelFile(buffer, fileName) {
  import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}


const tableHeader = (
    <div className="p-d-flex export-buttons ">
        <Button type="button" icon="pi pi-file-excel" className="p-button-success p-mr-2 btnTabla" onClick={exportExcel} data-pr-tooltip="XLS" />
        <Button type="button" icon="pi pi-file-pdf"  className="p-button-warning p-mr-2 btnTabla" onClick={exportPdf} data-pr-tooltip="PDF" />


        <div className="p-ml-auto" >

        Trabajadora
        <Dropdown value={selectedTrabajador} options={listadoTrabajadores} onChange={onTrabajadorChange} name="trabajador"
          optionLabel="label" optionValue="value" style={{ width: '180px' }}
          placeholder="Seleccione Estado" className="p-inputtext-sm marginLR"/>

        Fecha Desde
        <Calendar name="fechaDesde" id="spanish" value={fechaDesde} onChange={(e) => setFechaDesde(e.value)}  locale="es" 
            placeholder="Seleccione Fecha Desde"
            style={{ width: '180px' }} dateFormat="dd/mm/yy" className="marginLR"/>

        Fecha Hasta
        <Calendar id="spanish" value={fechaHasta} onChange={(e) => setFechaHasta(e.value)} locale="es" 
            placeholder="Seleccione Fecha Hasta"
            style={{ width: '180px' }} dateFormat="dd/mm/yy"  className="marginLR" />
        <Button icon="pi pi-search" className="p-button-rounded  p-button-primary p-mr-2 p-ml-auto btnTabla" 
          onClick={btnSearchClick}
          tooltip="Buscar" tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }}/>
        </div>   
    </div>
);

const renderFooter = (name) => {
  return (
      <div>
          <Button label="No" icon="pi pi-times" onClick={() => setDisplayResponsive(false)} className="p-button-text" />
          <Button label="Yes" icon="pi pi-check" onClick={() => CreateSucursal() } autoFocus />
      </div>
  );
}


  function CreateSucursal()
  {
    if(!ValidaFormulario()) return;
    /* EJEMPLO DE ENVIAR STRING NO BORRAR
    var user4 = JSON.stringify("Trujujui");
    axios.post(`http://localhost:30604/api/Parametros/SucursalInsert`, user4,{headers: {'Content-Type': 'application/json'}})
    .then(res => {
      console.log(res);
      console.log(res.data);
    }); 
    */

    var datos = JSON.stringify(datosInsert);

    axios.post(`http://localhost:30604/api/Parametros/SucursalUpsert`, datos,{headers: {'Content-Type': 'application/json'}})
      .then(res => {
        toast.current.show({severity:'success', summary: '', detail: res.data, life: 3000});
      })
      .catch(err => {    
        if (err.response !== undefined) 
          toast.current.show({severity:'error', summary: '', detail: err.response.data, life: 10000});
        else
          toast.current.show({severity:'error', summary: '', detail: err.message, life: 10000});
    });
    setDisplayResponsive(false);
  }


  function ValidaFormulario()
  {
    
    if(datosInsert.sucursal=='')
    {
      toast.current.show({severity:'info', summary: '', detail:'Ingrese la sucursal', life: 3000});
      return false;
    }
    return true;

  }

  function CalcularTotalTabla(datos)
  {
    if(!datos) return;
    let total = 0;
    datos.forEach(element => total+= element.total);
    setTotalTabla(Utiles.formatCurrency(total));
  }

  let footerGroup = <ColumnGroup>
  <Row>
      <Column footer="Total:" colSpan={6} footerStyle={{textAlign: 'right'}}/>
      <Column footer={totalTabla} />
  </Row>
  </ColumnGroup>;

  return (
    <div>
    <Toast ref={toast}></Toast>
    <h2 style={{textAlign : "center"}}>Trabajos</h2>

    <DataTable value={datosTabla} header={tableHeader} paginator scrollable  
                    className="p-datatable-sm centerMargin" showGridlines
                    footerColumnGroup={footerGroup}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}" rows={10}
                    style={{width : "1200px"}} 
                    >
          <Column field="fecha" header="Fecha" style={{width:'110px'}}></Column>
          <Column field="manicurista" header="Manicurista" style={{width:'220px'}}></Column>
          <Column field="servicio" header="Servicio" style={{width:'529px'}}></Column>
          <Column field="precio" header="Precio" style={{width:'70px'}}></Column>
          <Column field="boleta" header="Boleta" style={{width:'70px'}}></Column>
          <Column field="descuento" header="Descuento" style={{width:'100px'}}></Column>
          <Column field="total" header="Total" style={{width:'100px'}}></Column>
      </DataTable>

    </div>
  );
}

export default Trabajos;
