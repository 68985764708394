import React from "react";
//import { useEffect, useState } from "react";
import { Button } from 'primereact/button';

function Inicio() {
  return (
    <div>
    <h1>Inicio</h1>

    </div>
  );
}

export default Inicio;
