import React from "react";
import { useEffect, useState, useRef } from "react";
import 'primeflex/primeflex.css';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";
import { Toast } from 'primereact/toast';
import axios from 'axios';

import './Login.css';

function Login() {

  /*
  localStorage.setItem('miGato', 'Juan');
var cat = localStorage.getItem('miGato');
localStorage.removeItem('miGato');
localStorage.clear();

  */
  const apiURL = process.env.REACT_APP_API_URL;
  const toast = useRef(null);
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');



  let history = useHistory();
  const [loading2, setLoading2] = useState(false);


  const onLoadingClick2 = () => {
    setLoading2(true);

    if(!user)
    {
      toast.current.show({severity:'info', summary: '', detail:'Ingrese el usuario', life: 3000});
      setLoading2(false);
      return false;
    }
  
    if(!password)
    {
      toast.current.show({severity:'info', summary: '', detail:'Ingrese la contraseña', life: 3000});
      setLoading2(false);
      return false;
    }

    // if(password!="1234")
    // {
    //   toast.current.show({severity:'info', summary: '', detail:'Contraseña Incorrecta', life: 3000});
    //   setLoading2(false);
    //   return false;
    // }

    // setTimeout(() => {
    //   localStorage.setItem('usuario', 'Juan');
    //   setLoading2(false);
    //   history.push("/");
    // }, 2000);

    axios.get(`${apiURL}Login/Login/${user}/${password}`)
    .then(res => {
      localStorage.setItem('token', res.data);
      setLoading2(false);
      history.push("/");
    })      
    .catch(err => {    
      if (err.response !== undefined) 
        toast.current.show({severity:'error', summary: '', detail: err.response.data, life: 10000});
      else
        toast.current.show({severity:'error', summary: '', detail: err.message, life: 10000});

      setLoading2(false);
  });




}

  return (

    <div>
      <Toast ref={toast}></Toast>
      <div  className="cajaDatosLogin">
        
        <div style={{width:100, display: "inline-block"}}>Usuario</div>
        <InputText id="firstname3" type="text" size={22} value={user} onChange={(e) => setUser(e.target.value)}/>
        <p></p>
        
        <div style={{width:100, display: "inline-block"}}>Contraseña</div>
        <Password id="firstname3s" type="text" size={22} weakLabel="Débil" mediumLabel="Medio" strongLabel="Fuerte"
          value={password} onChange={(e) => setPassword(e.target.value)}
        promptLabel="Ingrese su contraseña" />
        <p></p>
        <p>
        <Button label="Iniciar sesión" style={{width:290, display: "inline-block"}}  loading={loading2} onClick={onLoadingClick2} />
        </p>
      </div>

    </div>



  );
}

export default Login;
