import React from "react";
import { useEffect, useState, useRef } from "react";
import { estados } from '../../constantes/estados';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { localeEsp } from '../../constantes/localeEsp';
import { Utiles } from '../../utils/utiles';

import axios from 'axios';

function IngresarTrabajos() {
  const apiURL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [datosTabla, setDatosTabla] = useState(null);
  const [displayResponsive, setDisplayResponsive] = useState(false);

  const [boleta, setBoleta] = useState(null);
  const [total, setTotal] = useState(null);
  const [precio, setPrecio] = useState(null);
  const [btnSaveLoading, setBtnSaveLoading] = useState(false);

  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);

  const [listadoTrabajadores, setListadoTrabajadores] = useState(null);
  const [selectedTrabajador, setSelectedTrabajador] = useState(null);

  const [listadoServicios, setListadoServicios] = useState(null);
  const [selectedServicio, setSelectedServicio] = useState(null);

  const [selectedDescuento, setSelectedDescuento] = useState(null);

  const [deleteTrabajoDialog, setDeleteTrabajoDialog] = useState(false);

    let trabajoSeleccionadoVacio = {
      boleta: null,
      descuento: null,
      fecha: null,
      id: null,
      manicurista: null,
      precio: null,
      servicio: null,
  };
  const [trabajoSeleccionado, setTrabajoSeleccionado] = useState(trabajoSeleccionadoVacio);


  addLocale('es', localeEsp);

  const [datosInsert, setDatosInsert] = useState({
    id: 10,
    sucursal: '',
    aforo_maximo: null,
    estado: 1
  });

  const listaDescuentos = [
    { label: '---', value: '0' },
    { label: '10%', value: '10' },
    { label: '20%', value: '20' },
    { label: '30%', value: '30' },
    { label: '40%', value: '40' },
    { label: '50%', value: '50' }
  ];

  useEffect(() => {
    var fechaHoy = new Date();
    fechaHoy.setHours(0, 0, 0, 0);
    setFechaDesde(fechaHoy);
    setFechaHasta(fechaHoy);


    axios.get(`${apiURL}SalonTrabajo/GetUltimosTrabajos`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        setDatosTabla(response.data);
      })
      .catch(err => {
        if (err.response !== undefined)
          toast.current.show({ severity: 'error', summary: '', detail: err.response.data, life: 10000 });
        else
          toast.current.show({ severity: 'error', summary: '', detail: err.message, life: 10000 });
      });



    fetch(
      `${apiURL}trabajador/GetListaTrabajadorasSalon`,
      {
        method: "GET",
        //headers: new Headers({Accept: "application/vnd.github.cloak-preview"})
      }
    )
      .then(res => res.json())
      .then(response => {
        setListadoTrabajadores(response);
        //setCommitHistory(response.items);
        setIsLoading(false);
      })
      .catch(error => console.log(error));


    fetch(`${apiURL}SalonServicioProducto/GetListadoIngreso`, {
      method: "GET",
      //headers: new Headers({Accept: "application/vnd.github.cloak-preview"})
    })
      .then(res => res.json()).then(response => {
        setListadoServicios(response);
        //setCommitHistory(response.items);
        setIsLoading(false);
      }).catch(error => console.log(error));



  }, [apiURL]);

  const onTrabajadorChange = (e) => {
    setSelectedTrabajador(e.value);
  }

  const onServicioChange = (e) => {
    let precioServicio = listadoServicios.find(servicio => servicio.id === e.value).precio;
    setPrecio(precioServicio);
    if (selectedDescuento && selectedDescuento != 0) setTotal(precioServicio - (precioServicio * selectedDescuento / 100));
    else setTotal(precioServicio);
    setSelectedServicio(e.value);
  }

  const onDescuentoChange = (e) => {
    if (selectedServicio) {
      let precioServicio = listadoServicios.find(servicio => servicio.id === selectedServicio).precio;
      setTotal(precioServicio - (precioServicio * e.value / 100));
    }

    setSelectedDescuento(e.value);
  }

  function btnSaveClick() {
    setBtnSaveLoading(true);

    if (!selectedTrabajador) {
      toast.current.show({ severity: 'warn', summary: '', detail: 'Seleccione la trabajadora', life: 3000 });
      setBtnSaveLoading(false);
      return false;
    }

    if (!selectedServicio) {
      toast.current.show({ severity: 'warn', summary: '', detail: 'Seleccione el servicio', life: 3000 });
      setBtnSaveLoading(false);
      return false;
    }

    if (!total) {
      toast.current.show({ severity: 'warn', summary: '', detail: 'Ingrese el total', life: 3000 });
      setBtnSaveLoading(false);
      return false;
    }

    let nuevoTrabajo = {
      id: null,
      fecha: Utiles.jsDateToCSharp(fechaDesde),
      manicurista: selectedTrabajador,
      producto_servicio: selectedServicio,
      precio: precio,
      boleta: boleta,
      descuento: !selectedDescuento ? null : selectedDescuento === "0" ? null : parseInt(selectedDescuento),
      total: total,
      estado: 1
    };

    var datos = JSON.stringify(nuevoTrabajo);

    axios.post(`${apiURL}SalonTrabajo`, datos, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        toast.current.show({ severity: 'success', summary: '', detail: res.data, life: 3000 });
        axios.get(`${apiURL}SalonTrabajo/GetUltimosTrabajos`, {
          headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
          }
        })
          .then(response => {
            setDatosTabla(response.data);
          })

        setSelectedTrabajador(null);
        setSelectedServicio(null);
        setBoleta(null);
        setSelectedDescuento(null);
        setTotal(null);
        setBtnSaveLoading(false);
      })
      .catch(err => {
        if (err.response.data !== undefined)
          toast.current.show({ severity: 'error', summary: '', detail: err.response.data, life: 10000 });
        else
          toast.current.show({ severity: 'error', summary: '', detail: 'Error al guardar el trabajo', life: 10000 });

        setBtnSaveLoading(false);
      });


  }


  const tableHeader = (
    <div className="p-d-flex export-buttons ">
      <div className="p-ml-auto" >
        Fecha
        <Calendar name="fechaDesde" id="spanish" value={fechaDesde} onChange={(e) => setFechaDesde(e.value)} locale="es"
          placeholder="Seleccione Fecha Desde" style={{ width: '110px' }}
          dateFormat="dd/mm/yy" className="marginLR" />
        Manicurista
        <Dropdown value={selectedTrabajador} options={listadoTrabajadores} onChange={onTrabajadorChange} name="trabajador"
          optionLabel="label" optionValue="value" style={{ width: '120px' }}
          placeholder="Seleccione Estado" className="p-inputtext-sm marginLR" />
        Servicio
        <Dropdown value={selectedServicio} options={listadoServicios} onChange={onServicioChange}
          optionLabel="descripcion" optionValue="id" filter filterBy="descripcion" placeholder="Seleccione servicio"
          className="marginLR" style={{ width: '230px' }}
        //valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} showClear 
        />

        Boleta
        <InputNumber inputId="integeronly" value={boleta} className="marginLR" size={4}
          onValueChange={(e) => setBoleta(e.value)} />
        Descuento
        <Dropdown value={selectedDescuento} options={listaDescuentos} onChange={onDescuentoChange} className="marginLR"
          placeholder="" style={{ width: '80px' }} />
        Total
        <InputNumber inputId="total"
          value={total}
          className="marginLR" size={5}
          onValueChange={(e) => setTotal(e.value)}
        />

        <Button icon="pi pi-save" className="p-button-rounded  p-button-success p-mr-2 p-ml-auto btnTabla"
          onClick={btnSaveClick} loading={btnSaveLoading}
          tooltip="Guardar" tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} />
      </div>
    </div>
  );

  const confirmDeleteTrabajo = (trabajo_seleccionado) => {
    setTrabajoSeleccionado(trabajo_seleccionado);
    setDeleteTrabajoDialog(true);
  }

  const deleteTrabajo = (product) => {


    axios.delete(`${apiURL}SalonTrabajo/DeleteTrabajo?idtrabajo=${trabajoSeleccionado.id}`, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => {
        toast.current.show({ severity: 'success', summary: '', detail: 'Trabajo eliminado', life: 3000 });
        axios.get(`${apiURL}SalonTrabajo/GetUltimosTrabajos`, {
          headers: {
            'Authorization': `bearer ${localStorage.getItem('token')}`
          }
        })
          .then(response => {
            setDatosTabla(response.data);
          })
      })
      .catch(err => {
        if (err.response.data !== undefined)
          toast.current.show({ severity: 'error', summary: '', detail: err.response.data, life: 10000 });
        else
          toast.current.show({ severity: 'error', summary: '', detail: 'Error al eliminar el trabajo', life: 10000 });
      });

    setDeleteTrabajoDialog(false);
  }

  const hideDeleteTrabajoDialog = () => {
    setDeleteTrabajoDialog(false);
}

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-trash" className="p-button-rounded p-button-warning btnTabla" onClick={() => confirmDeleteTrabajo(rowData)} />
      </React.Fragment>
    );
  }

  const deleteTrabajoDialogFooter = (
    <React.Fragment>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTrabajoDialog} />
        <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteTrabajo} />
    </React.Fragment>
);



  return (
    <div>
      <Toast ref={toast}></Toast>
      <h2 style={{ textAlign: "center" }}>Ingresar Trabajos</h2>

      <div className="card">
        <DataTable value={datosTabla} header={tableHeader} paginator
          className="p-datatable-sm" showGridlines
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando últimos 5 trabajos" rows={10}
        >
          <Column field="fecha" header="Fecha" style={{ width: '15%' }}></Column>
          <Column field="manicurista" header="Manicurista" style={{ width: '15%' }}></Column>
          <Column field="servicio" header="Servicio" style={{ width: '28%' }}></Column>
          <Column field="precio" header="Precio" style={{ width: '10%' }}></Column>
          <Column field="boleta" header="Boleta" style={{ width: '10%' }}></Column>
          <Column field="descuento" header="Descuento" style={{ width: '132' }}></Column>
          <Column field="total" header="Total" style={{ width: '10%' }}></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>

      <Dialog visible={deleteTrabajoDialog} style={{ width: '450px' }} header="Borrar Trabajo" modal 
        footer={deleteTrabajoDialogFooter} onHide={hideDeleteTrabajoDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} /><br/>
                    {trabajoSeleccionado && <span>¿Desea borrar el trabajo: <b>{
                    trabajoSeleccionado.fecha}, {trabajoSeleccionado.servicio}, {trabajoSeleccionado.manicurista}
                      </b>?</span>}
                </div>
      </Dialog>

    </div>
  );
}

export default IngresarTrabajos;
