import React from "react";
import { useEffect, useState, useRef } from "react";
import { estados } from '../constantes/estados';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import axios from 'axios';

function Sucursal() {
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [sucursales, setSucursales] = useState(null);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [selectedSucursal, setSelectedSucursal] = useState({ name: 'Activo', code: '1' });

    const [datosInsert, setDatosInsert] = useState({
        id: 10,
        sucursal: '',
        aforo_maximo: null,
        estado: 1
    });

  
  useEffect(() => {
    fetch(
      //`http://200.119.240.122/wsaforo/api/Parametros/Sucursales`,
      `http://localhost:30604/api/Parametros/Sucursales`,   
      {
        method: "GET",
        //headers: new Headers({Accept: "application/vnd.github.cloak-preview"})
      }
    )
      .then(res => res.json())
      .then(response => {
         setSucursales(response);
         //setCommitHistory(response.items);
         setIsLoading(false);
      })
      .catch(error => console.log(error));
  },[]);


const onSucursalChange = (e) => {
  setDatosInsert({
    ...datosInsert,
    [e.target.name] : parseInt(e.value.code, 10)
  });
  setSelectedSucursal(e.value); 
}

const handleNumberChange = (e) => {
  console.log();
  setDatosInsert({
    ...datosInsert,
    aforo_maximo : e.value
});
}

const handleInputChange = (event) => {
  setDatosInsert({
      ...datosInsert,
      [event.target.name] : event.target.value
  });
}


const tableHeader = (
    <div className="p-d-flex export-buttons ">
        <Button type="button" icon="pi pi-file-excel" className="p-button-success p-mr-2 btnTabla" data-pr-tooltip="XLS" />
        <Button type="button" icon="pi pi-file-pdf"  className="p-button-warning p-mr-2 btnTabla" data-pr-tooltip="PDF" />
       
        <Button icon="pi pi-search" className="p-button-rounded  p-button-primary p-mr-2 p-ml-auto btnTabla" 
          tooltip="Buscar" tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }}/>
        <Button icon="pi pi-plus"   className="p-button-rounded p-button-primary p-mr-1 btnTabla"
          onClick={() => setDisplayResponsive(true)}  
          tooltip="Crear Sucursal" tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }}/>    
    </div>
);

const renderFooter = (name) => {
  return (
      <div>
          <Button label="No" icon="pi pi-times" onClick={() => setDisplayResponsive(false)} className="p-button-text" />
          <Button label="Yes" icon="pi pi-check" onClick={() => CreateSucursal() } autoFocus />
      </div>
  );
}


  function CreateSucursal()
  {
    if(!ValidaFormulario()) return;
    /* EJEMPLO DE ENVIAR STRING NO BORRAR
    var user4 = JSON.stringify("Trujujui");
    axios.post(`http://localhost:30604/api/Parametros/SucursalInsert`, user4,{headers: {'Content-Type': 'application/json'}})
    .then(res => {
      console.log(res);
      console.log(res.data);
    }); 
    */

    var datos = JSON.stringify(datosInsert);

    axios.post(`http://localhost:30604/api/Parametros/SucursalUpsert`, datos,{headers: {'Content-Type': 'application/json'}})
      .then(res => {
        toast.current.show({severity:'success', summary: '', detail: res.data, life: 3000});
      })
      .catch(err => {    
        if (err.response !== undefined) 
          toast.current.show({severity:'error', summary: '', detail: err.response.data, life: 10000});
        else
          toast.current.show({severity:'error', summary: '', detail: err.message, life: 10000});
    });
    setDisplayResponsive(false);
  }


  function ValidaFormulario()
  {
    
    if(datosInsert.sucursal=='')
    {
      toast.current.show({severity:'info', summary: '', detail:'Ingrese la sucursal', life: 3000});
      return false;
    }
    return true;

  }


  return (
    <div>
    <Toast ref={toast}></Toast>
    <h1>Sucursales</h1>

    <Dialog header="Nueva Sucursal" visible={displayResponsive} onHide={() => setDisplayResponsive(false)} 
    //style={{ width: '90vw' }}
    style={{ width: '600px', height: '340px' }}
    footer={renderFooter('displayResponsive')}>

    <div style={{height: '165px'}}>   

      <div className="p-fluid">
      <div className="p-field p-grid">
          <label htmlFor="sucursal" className="p-col-12 p-md-3">Sucursal</label>
          <div className="p-col-12 p-md-9">
              <InputText id="sucursal" onChange={handleInputChange} value={datosInsert.sucursal}
              type="text" maxLength="50" className="p-inputtext-sm" name="sucursal" />
          </div>
      </div>
      <div className="p-field p-grid">
          <label htmlFor="aforo" className="p-col-12 p-md-3">Aforo Maximo</label>
          <div className="p-col-12 p-md-9">
          <InputNumber id="aforo" mode="decimal" onChange={handleNumberChange} name="aforo_maximo" value={datosInsert.aforo_maximo}
            useGrouping={false} max={9999999} className="p-inputtext-sm"/>
          </div>
      </div>
      <div className="p-field p-grid">
          <label htmlFor="estado" className="p-col-12 p-md-3">Estado</label>
          <div className="p-col-12 p-md-9">
          <Dropdown value={selectedSucursal} options={estados} onChange={onSucursalChange} optionLabel="name" name="estado"
          placeholder="Seleccione Estado" className="p-inputtext-sm"/>
          </div>
      </div>
      </div>
      {/*     <div     style={{minHeight: '150px'}}>    </div> */}
    </div>                
    </Dialog>

    <div className="card">
      <DataTable value={sucursales} header={tableHeader} paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}" rows={5} 
                    >
        <Column field="id" header="id"></Column>
        <Column field="sucursal" header="Sucursal"></Column>
        <Column field="aforo" header="Aforo"></Column>
        <Column field="estado" header="Estado"></Column>
      </DataTable>
    </div>

    </div>
  );
}

export default Sucursal;
