import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, NavLink} from "react-router-dom";
import { Menubar } from 'primereact/menubar';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { PanelMenu } from 'primereact/panelmenu';
import './Layout.css'; 

import Inicio from './components/Inicio' 
import Usuario from './components/Usuario' 
import Sucursal from './components/Sucursal' 
import Movimientos from './components/Movimientos' 

import Trabajos from './components/salon/Trabajos'
import IngresarTrabajos from './components/salon/IngresarTrabajos'

import { useHistory } from "react-router-dom";

function Layout() {

 let history = useHistory();

 const items2 = [

   {
      label:'',
      command:()=>{ setVisible(true)},
      icon:'pi pi-bars',
   },
   // {
   //    label:'Aforo',
   //    items:[
   //       {
   //          label:'Sucursales',
   //          command:()=>{  history.push("/sucursales") },
   //          icon:'pi pi-fw pi-file-o',
   //       },
   //       {
   //          label:'Movimientos',
   //          command:()=>{  history.push("/movimientos") },
   //          icon:'pi pi-fw pi-file-o',
   //       },
   //       {
   //          label:'Export',
   //          icon:'pi pi-fw pi-file-o',
   //       }
   //    ]
   // },
   // {
   //    label:'Events',
   //    icon:'pi pi-fw pi-calendar',
   //    command:()=>{  history.push("/") },
   //    items:[
   //       {
   //          label:'Edit',
   //          icon:'pi pi-fw pi-pencil',
   //          items:[
   //             {
   //                label:'Save',
   //                icon:'pi pi-fw pi-calendar-plus'
   //             },
   //             {
   //                label:'Delete',
   //                icon:'pi pi-fw pi-calendar-minus'
   //             }
   //          ]
   //       },
   //       {
   //          label:'Archieve',
   //          command:()=>{  history.push("/bla") },
   //          icon:'pi pi-fw pi-calendar-times',
   //          items:[
   //             {
   //                label:'Remove',
   //                command:()=>{  history.push("/") },
   //                icon:'pi pi-fw pi-calendar-minus'
   //             }
   //          ]
   //       }
   //    ]
   // },
   {
      label:'Salón',
      icon:'pi pi-fw pi-calendar',
      items:[
         {
            label:'Trabajos',
            command:()=>{  history.push("/trabajos") },
            icon:'pi pi-fw pi-pencil'
         },
         {
            label:'Ingresar Trabajos',
            command:()=>{  history.push("/ingresar-trabajos") },
            icon:'pi pi-fw pi-pencil'
         }
         // ,{
         //    label:'Archieve',
         //    command:()=>{  history.push("/bla") },
         //    icon:'pi pi-fw pi-calendar-times',
         //    items:[
         //       {
         //          label:'Remove',
         //          command:()=>{  history.push("/") },
         //          icon:'pi pi-fw pi-calendar-minus'
         //       }
         //    ]
         // }
      ]
   },
   {
      label:'Cerrar Sesion',
      command:()=>{  localStorage.removeItem('token'); history.push("/");  },
      icon:'pi pi-fw pi-pencil'

   }

];


const [visible, setVisible] = useState(false);

  return (
    <div>

         <Menubar model={items2}   
         />

         <Sidebar visible={visible} onHide={() => setVisible(false)} style={{"overflowY":"scroll",padding:0}}>
            <h2>Menu</h2>
            <PanelMenu model={items2} />
         </Sidebar>



         <Switch>
            <Route path="/" exact>
               <Inicio />
            </Route>
            <Route path="/bla">
               <Usuario />
            </Route>
            <Route path="/sucursales">
               <Sucursal />
            </Route>
            <Route path="/movimientos">
               <Movimientos />
            </Route>
            <Route path="/trabajos">
               <Trabajos />
            </Route>
            <Route path="/ingresar-trabajos">
               <IngresarTrabajos />
            </Route> 

         </Switch>

    </div>
  );
}

export default Layout;
