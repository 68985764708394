import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from 'axios';

import { Utiles } from '../utils/utiles';
import { estados } from '../constantes/estados';
import { localeEsp } from '../constantes/localeEsp';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';


//import { useEffect, useState } from "react";

function Movimientos() {
    const [isLoading, setIsLoading] = useState(true);
    const [movimientos, setMovimientos] = useState(null);
    const [sucursal, setSucursal] = useState(null);
    const [fechaDesde, setFechaDesde] = useState(null);
    const [fechaHasta, setFechaHasta] = useState(null);
    const [rut, setRut] = useState('');

    addLocale('es', localeEsp);

  function btnLimpiarClick()
  {
    setRut('');
    setSucursal(null);
    setFechaDesde(null);
    setFechaHasta(null);
  }

  function btnSearchClick()
  {
    var person = {
      rut:rut,
      fechaDesde: fechaDesde == null ? null : Utiles.jsDateToCSharp(fechaDesde),
      fechaHasta: fechaHasta == null ? null : Utiles.jsDateToCSharp(fechaHasta), 
      sucursal: sucursal == null ? null: parseInt(sucursal.code, 10)
    };

    var datos = JSON.stringify(person);

    axios.post(`http://localhost:30604/api/Parametros/GetMovimientosMostrarWeb`, datos,{headers: {'Content-Type': 'application/json'}})
    .then(response => {
      console.log(response);
      setMovimientos(response.data);
      //setCommitHistory(response.items);
      setIsLoading(false);
    })
    .catch(err => {    
      console.log(err);
      /*
      if (err.response !== undefined) 
        toast.current.show({severity:'error', summary: '', detail: err.response.data, life: 10000});
      else
        toast.current.show({severity:'error', summary: '', detail: err.message, life: 10000});*/
    });

  }



  
  useEffect(() => {
    var fechaHoy = new Date();
    var fechaHoym30 = new Date();
    fechaHoym30.setDate(fechaHoym30.getDate() - 30);
    setFechaDesde(new Date(fechaHoym30.getFullYear(),fechaHoym30.getMonth(), fechaHoym30.getDate()));
    setFechaHasta(new Date(fechaHoy.getFullYear(),fechaHoy.getMonth(), fechaHoy.getDate()));

    fetch(
      //`http://200.119.240.122/wsaforo/api/Parametros/Sucursales`,
      `http://localhost:30604/api/Parametros/GetMovimientosMostrarWeb/1/10`,
      {
        method: "GET",
        //headers: new Headers({Accept: "application/vnd.github.cloak-preview"})
      }
    )
      .then(res => res.json())
      .then(response => {
        setMovimientos(response);
        //setCommitHistory(response.items);
        setIsLoading(false);
      })
      .catch(error => console.log(error));

  }, []);

      function dateTemplate(rowData, column) {
        const event = new Date(rowData['fecha_hora']); 
        return event.toLocaleTimeString([], { day: 'numeric',month: 'numeric',year: 'numeric', hour: '2-digit', minute: '2-digit' })
        };


        const tableHeader = ( 
          <div className="p-d-flex export-buttons ">
            <div className="p-ml-auto" >
              Fecha Desde
            <Calendar name="fechaDesde" id="spanish" value={fechaDesde} onChange={(e) => setFechaDesde(e.value)}  locale="es" 
            placeholder="Seleccione Fecha Desde"
            style={{ width: '200px' }} dateFormat="dd/mm/yy" className="marginLR"/>

            Fecha Hasta
            <Calendar id="spanish" value={fechaHasta} onChange={(e) => setFechaHasta(e.value)} locale="es" 
            placeholder="Seleccione Fecha Hasta"
            style={{ width: '200px' }} dateFormat="dd/mm/yy"  className="marginLR" />

              Rut
              <InputText name="rut" type="text" className="p-inputtext-sm marginLR" placeholder="Ingrese Rut" 
              value={rut} onChange={(e) => setRut(e.target.value)}
              />
              Sucursal

              <Dropdown value={sucursal} options={estados} optionLabel="name" name="estado" 
                onChange={(e) => setSucursal(e.value)}  
                style={{ width: '200px' }} 
                placeholder="Seleccione Sucursal" className="p-inputtext-sm b marginLR" />

              <Button icon="pi pi-search" className="p-button-rounded  p-button-primary p-mr-2 btnTabla"
                onClick={btnSearchClick}
                tooltip="Buscar" tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} />

              <Button icon="pi pi-times" className="p-button-rounded  p-button-primary p-mr-2 btnTabla"
                onClick={btnLimpiarClick}
                tooltip="Limpiar" tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }} />  
            </div>
            

          </div>
        );

  return (
    <div>
    <div className="center"><h1>Movimientos</h1></div>

    <div className="card">
      <DataTable value={movimientos} paginator header={tableHeader}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}" rows={10} 
                    >
        <Column field="rut" header="Rut"></Column>
        <Column field="sucursal" header="Sucursal"></Column>
        <Column field="desc_tipo_movimiento" header="Movimiento"></Column>
        <Column field="fecha_hora" header="Fecha" body={dateTemplate}></Column>
      </DataTable>
    </div>
    </div>
  );
}

export default Movimientos;
