export const Utiles = {
    foo() { console.log('foo') }, 
    bar() { console.log('bar') },

    jsDateToCSharp(fecha) { 
        var x = new Date(fecha); 
        let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
        let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
        x.setHours(hoursDiff);
        x.setMinutes(minutesDiff);
        return x.toJSON();
    },
    jsdtcs(fecha) { 
        var x = new Date(fecha); 
        let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
        let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
        x.setHours(hoursDiff);
        x.setMinutes(minutesDiff);
        return x.toJSON();
    },
    formatCurrency(value) {
        return value.toLocaleString('es-CL', {style: 'currency', currency: 'CLP'});
    },
};