import logo from './logo.svg';
import './App.css';
import 'primeflex/primeflex.css';
import Layout from './Layout' 
import Login from './Login';
import { BrowserRouter as Router,Switch, Route } from 'react-router-dom';


function App() {



  const PrivateRoute = ({ component, ...options }) => {
    const finalComponent = estaAutenticado() ? component : Login;
  
    return <Route {...options} component={finalComponent} />;
  };


  function estaAutenticado()
  {
    let token = localStorage.getItem('token');
    if(token == null) return false;
    else
    {      
      //const payload = JSON.parse(atob(token.split(".")[1]));
      const payload = JSON.parse( Buffer.from(token.split(".")[1], 'base64').toString('binary'));
      const now = new Date();
      const veinticuatroHoras =  60 * 60 * 24;

      if( payload.exp - now.getTime() / 1000 < veinticuatroHoras ){
        localStorage.removeItem('token');
        return false;
      } 

    }
    return true;
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute path="/" component={Layout} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
